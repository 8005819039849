<template lang="pug">
  div
    slot(:hcb="hcb" :other-hcbs="otherHcbs")

</template>

<script>
  import vueColorbarSvc from "@services/vueColorbarSvc";

  export default {
    name: "ClosestHairColorBar",
    inject: ['getObjProperty'],

    data: () => ({
      hcb: null,
      otherHcbs: null
    }),

    mounted() {
      vueColorbarSvc.getClosestLocationsByIp().then(res => {
        let hcb = this.getObjProperty(res, 'data.0');
        if (hcb) {
          this.hcb = hcb;
          this.otherHcbs = [this.getObjProperty(res, 'data.1'), this.getObjProperty(res, 'data.2')];
        }
      });
    }
  };
</script>

<style scoped lang="stylus">
</style>